<template>
  <div>
    <b-card
      no-body
      class="liveinfo-form"
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="10"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <b-form-datepicker
              v-model="query.createDateFr"
              class="el-def"
              placeholder="등록일(Fr)"
            />
            <label class="ml-25 mr-50">~</label>
            <b-form-datepicker
              v-model="query.createDateTo"
              class="el-def"
              placeholder="등록일(To)"
            />
            <b-form-input
              v-model="query.filterStr"
              class="el-def"
              placeholder="제목을 검색하세요."
              @keyup.enter="refetchData"
            />
          </b-col>
          <!-- Search -->
          <b-col
            cols="12"
            md="2"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="primary"
              class="btn-search"
              @click.prevent="refetchData"
            >
              조회
            </b-button>
          </b-col>
        </b-row>
      </div>
    </b-card>
    <b-card
      no-body
    >
      <div class="m-2">
        <b-row>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start"
          >
            <h5 class="mb-0 ml-50">
              설문지 리스트 [{{ totalRecords }}] 건
            </h5>
          </b-col>
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-end"
          >
            <b-button
              variant="outline-secondary"
              @click.prevent="previewPaper"
            >
              <feather-icon
                icon="EyeIcon"
                class="mr-50"
              />
              <span>미리보기</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="cloneNewPaper"
            >
              <feather-icon
                icon="CopyIcon"
                class="mr-50"
              />
              <span>설문 복사</span>
            </b-button>
            <b-button
              variant="outline-primary"
              class="ml-3"
              @click.prevent="newPaper"
            >
              <feather-icon
                icon="PlusSquareIcon"
                class="mr-50"
              />
              <span>신규 등록</span>
            </b-button>
            <b-button
              variant="primary"
              class="ml-1"
              @click.prevent="editPaper"
            >
              <feather-icon
                icon="EditIcon"
                class="mr-50"
              />
              <span>수정</span>
            </b-button>
            <b-button
              variant="outline-secondary"
              class="ml-1"
              @click.prevent="removePaper"
            >
              <feather-icon
                icon="Trash2Icon"
                class="mr-50"
              />
              <span>삭제</span>
            </b-button>
          </b-col>
        </b-row>
      </div>
      <b-table
        ref="surveyPaperTable"
        primary-key="id"
        :items="fetchSurveyPapers"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDesc"
        responsive
        sticky-header
        selectable
        select-mode="single"
        class="position-relative"
        style="max-height: 680px;"
        @row-selected="onRowSelectedPaper"
      >
        <template #cell(title)="data">
          <div style="overflow: hidden; text-overflow: ellipsis; white-space: nowrap;">
            {{ data.value }}
          </div>
        </template>
        <template #cell(createDate)="data">
          <span class="text-nowrap">
            {{ data.value | $dateFormatter('YYYY-MM-DD HH:mm') }}
          </span>
        </template>
      </b-table>
      <b-pagination
        v-model="currPage"
        :total-rows="totalRecords"
        limit="10"
        per-page="15"
        align="center"
      />
    </b-card>
    <b-modal
      v-model="showPreview"
      size="xl"
      title="설문지 미리보기"
    >
      <iframe
        ref="previewFrame"
        :src="previewUrl"
        frameborder="0"
        width="1400"
        height="820"
        @load="loadPreview"
      />
    </b-modal>
  </div>
</template>

<script>
import axios from '@axios'
import dayjs from 'dayjs'
import {
  ref, watch, getCurrentInstance, onMounted,
} from '@vue/composition-api'
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import { errorFormatter } from '@core/utils/filter'

export default {
  components: {
  },

  setup() {
    onMounted(() => {
    })
    const toast = useToast()
    const instance = getCurrentInstance()
    const router = instance.proxy.$router
    const bvModal = instance.proxy.$bvModal
    const refs = instance.proxy.$refs

    const showPreview = ref(false)
    const previewUrl = `${process.env.VUE_APP_PREVIEW_URL}/#/app/preview-survey`

    const previewPaper = () => {
      if (!selectedPaper.value || !selectedPaper.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '미리보기 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      showPreview.value = true
    }

    const loadPreview = e => {
      if (e.type === 'load') refs.previewFrame.contentWindow.postMessage(selectedPaper.value.id, '*')
    }

    // Query Data
    const query = ref({
      createDateFr: dayjs().add(-3, 'month').format('YYYY-MM-DD'),
      createDateTo: dayjs().format('YYYY-MM-DD'),
      filterStr: null,
    })
    // Query Data End.

    // Main Table Config
    const surveyPaperTable = ref(null)
    const sortBy = ref('id')
    const isSortDesc = ref(true)
    const currPage = ref(1)
    const totalRecords = ref(0)
    const tableColumns = [
      { key: 'id', label: 'Id', sortable: true },
      {
        key: 'title', label: '설문 제목', sortable: true, thStyle: { width: '50%' },
      },
      { key: 'pageCnt', label: '페이지 수', sortable: true },
      { key: 'elementCnt', label: '질문 수', sortable: true },
      { key: 'createDate', label: '생성일', sortable: true },
    ]
    const refetchData = () => {
      surveyPaperTable.value.refresh()
    }
    watch([currPage], () => {
      refetchData()
    })

    const selectedPaper = ref({})
    const onRowSelectedPaper = items => {
      const item = items[0]
      selectedPaper.value = item
    }

    const cloneNewPaper = () => {
      if (!selectedPaper.value || !selectedPaper.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '복사할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }

      bvModal
        .msgBoxConfirm(`${selectedPaper.value.title} 설문의 사본을 생성하시겠습니까?`, {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.post(`/fa/survey-paper/clone/${selectedPaper.value.id}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '복사 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
            })
            .catch(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '복사하는 과정에서 오류가 발생하였습니다.',
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    const newPaper = () => {
      router.push({ name: 'apps-survey-paper-manage', params: { paper: { id: null } } })
    }

    const editPaper = () => {
      if (!selectedPaper.value || !selectedPaper.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '수정할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      router.push({ name: 'apps-survey-paper-manage', query: { id: selectedPaper.value.id } })
    }
    // Main Table Config End.

    const fetchSurveyPapers = (ctx, callback) => {
      axios.post('/fa/survey-paper/list', {
        search: {
          createDateFr: query.value.createDateFr ? `${query.value.createDateFr}T00:00:00` : null,
          createDateTo: query.value.createDateTo ? `${query.value.createDateTo}T23:59:59` : null,
          filterStr: query.value.filterStr,
        },
        sort: {
          predicate: sortBy.value,
          reverse: isSortDesc.value,
        },
        pagination: {
          number: currPage.value,
          count: 15,
        },
      })
        .then(rs => {
          const { items, totalRecord } = rs.data
          callback(items)
          totalRecords.value = totalRecord
        })
        .catch(() => {
          toast({
            component: ToastificationContent,
            props: {
              title: '데이터 조회에 실패하였습니다.',
              icon: 'AlertTriangleIcon',
              variant: 'danger',
            },
          })
        })
    }

    const removePaper = () => {
      if (!selectedPaper.value || !selectedPaper.value.id) {
        toast({
          component: ToastificationContent,
          props: {
            title: '삭제할 대상을 선택하세요.',
            icon: 'AlertTriangleIcon',
            variant: 'warning',
          },
        })
        return
      }
      bvModal
        .msgBoxConfirm('삭제 하시겠습니까?', {
          size: 'sm',
          cancelVariant: 'outline-secondary',
          centered: true,
        })
        .then(confirm => {
          if (!confirm) return

          axios.delete(`/fa/survey-paper/${selectedPaper.value.id}`)
            .then(() => {
              toast({
                component: ToastificationContent,
                props: {
                  title: '데이터 삭제 완료',
                  icon: 'CheckCircleIcon',
                  variant: 'success',
                },
              })
              refetchData()
            })
            .catch(err => {
              toast({
                component: ToastificationContent,
                props: {
                  title: errorFormatter(err, '삭제하는 과정에서 오류가 발생하였습니다.'),
                  icon: 'AlertTriangleIcon',
                  variant: 'danger',
                },
              })
            })
        })
    }

    return {
      query,
      tableColumns,
      surveyPaperTable,
      sortBy,
      isSortDesc,
      currPage,
      refetchData,
      totalRecords,
      fetchSurveyPapers,
      onRowSelectedPaper,
      newPaper,
      editPaper,
      removePaper,
      cloneNewPaper,
      previewPaper,
      showPreview,
      previewUrl,
      loadPreview,
    }
  },
}
</script>

<style>
.modal-xl {
  margin-left: auto;
  margin-right: auto;
  width: 1440px;
}
</style>
